<template>
  <div class="header-main">
    <!-- Header -->
    <nav-bar v-if="isUserLoggedIn"></nav-bar>
    <!-- Main -->
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
// Components
import NavBar from '@/components/ui/NavBar'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderMain',
  components: { NavBar },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn'])
  }
}
</script>
<style lang="scss">
@import '@/theme/variables.scss';
.header-main {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: $theme-background;
}
</style>
