<template>
  <div class="nav-menu" data-cy="nav-menu-element">
    <v-list v-bind="navMenuVOptions">
      <v-list-item
        v-for="link in getLinksByRole"
        :key="link.name"
        :to="'/' + link.name"
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ link.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'NavMenu',
  props: {
    // Propertis v-navigation-drawer -->
    // https://vuetifyjs.com/en/components/lists/#lists
    navMenuVOptions: {
      default() {
        return {
          dense: false
        }
      },
      type: Object
    }
  },
  data() {
    return {
      links: [
        {
          icon: 'mdi-chart-bar',
          label: 'Reportes',
          name: 'reports'
        },
        {
          icon: 'mdi-account-multiple',
          label: 'Usuarios',
          name: 'panel-users'
        },
        {
          icon: 'mdi-domain',
          label: 'Clientes',
          name: 'company-keys'
        },
        {
          icon: 'mdi-playlist-edit',
          label: 'Activaciones',
          name: 'company-activations'
        },
        {
          icon: 'mdi-sticker-emoji',
          label: 'Etiquetas QR',
          name: 'stickers-orders'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    /**
     * Get links to draw in nav menu
     *
     * @return {array} - array objects
     */
    getLinksByRole() {
      const allRoutes = get(this, '$router.options.routes[0].children', false)

      if (allRoutes === false) {
        return []
      }

      const { role } = this.user
      return allRoutes.reduce((sumRoutes, route) => {
        const currentlink = this.links.find(link => link.name === route.name)

        if (
          currentlink &&
          route.meta &&
          route.meta.roles &&
          route.meta.roles.indexOf(role) > -1
        ) {
          sumRoutes.push(currentlink)
        }
        return sumRoutes
      }, [])
    }
  }
}
</script>
