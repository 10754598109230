<template>
  <div class="user-menu">
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item to="/authentication-form">
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mi usuario</v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleCloseSession">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'UserMenu',
  mixins: [uiMixin],
  methods: {
    /**
     * Close user session
     */
    handleCloseSession() {
      this.closeSession()
    }
  }
}
</script>
