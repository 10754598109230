<template>
  <v-navigation-drawer
    :value="toggleMenu"
    v-bind="navDrawerVOptions"
    class="nav-drawer"
    @input="handleInput"
  >
    <slot></slot>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'NavDrawer',
  props: {
    toggleMenu: Boolean,
    // Propertis v-navigation-drawer -->
    // https://vuetifyjs.com/en/components/navigation-drawers/#navigation-drawers
    navDrawerVOptions: {
      default() {
        return {
          app: true,
          clipped: true,
          dark: true,
          color: '#173048'
        }
      },
      type: Object
    }
  },
  methods: {
    /**
     * Change the value (visible) to show or hide dialog
     *
     * @param {boolean} value - hide or show
     */
    handleInput(value) {
      this.$emit('onChangeVisibility', value)
    }
  }
}
</script>
