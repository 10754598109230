<template>
  <div class="nav-bar">
    <!-- Tool bar -->
    <v-app-bar v-bind="navBarVOptions">
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        @click="visible = !visible"
      />
      <!-- Brand -->
      <v-img
        :alt="getBrandTitle"
        contain
        max-width="150"
        :src="getBrandImage"
        height="40"
      />
      <v-spacer />
      <!-- User menu -->
      <UserMenu />
    </v-app-bar>
    <!-- Navigation Drawer -->
    <NavDrawer :toggle-menu="visible" @onChangeVisibility="handleVisibility">
      <NavMenu />
    </NavDrawer>
  </div>
</template>

<script>
// Utils
import { getPathImage } from '@/utils'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Components
import NavDrawer from './NavDrawer'
import NavMenu from './NavMenu'
import UserMenu from './UserMenu'

export default {
  name: 'NavBar',
  components: { NavDrawer, NavMenu, UserMenu },
  mixins: [uiMixin],
  props: {
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    navBarVOptions: {
      default() {
        return {
          app: true,
          flat: true,
          dark: true,
          color: 'secondary',
          clippedLeft: true
        }
      },
      type: Object
    }
  },
  data() {
    return {
      visible: true
    }
  },
  computed: {
    /**
     * Return brand image
     *
     * @return {string} - brand image
     */
    getBrandImage() {
      return getPathImage('brand.png', true)
    },
    /**
     * Return brand title
     *
     * @return {string} - brand title
     */
    getBrandTitle() {
      return process.env.VUE_APP_TITLE
    }
  },
  methods: {
    /**
     * Change the visibility of navDrawer
     *
     * @param {boolean} show - show or hide navDrawer
     */
    handleVisibility(show) {
      this.visible = show
    }
  }
}
</script>
